.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active {
  background: $primary-color;
  border-color: $primary-color;
}

.cursor-pointer:hover {
  cursor: pointer !important;
}

.cursor-zoom-in:hover {
  cursor: zoom-in !important;
}

.cursor-disallow:hover {
  cursor: not-allowed !important;
}

.cursor-help:hover {
  cursor: help !important;
}

.color-white {
  color: white;
}

.ws-pw {
  white-space: pre-wrap;
}

.ws-nw {
  white-space: nowrap;
}

.w-60 {
  width: 60% !important;
}

.fr-video video {
  max-width: 100%;
}

// Plugin Monkey Patch
.image-gallery-content {

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 40px !important;
    width: 20px !important;
  }

  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 20px !important;
    width: 20px !important;
  }
}

.circle-dropdown {
  .dropdown-menu.show {
    display: grid;
  }
}

.navBarRightDropdownItem {
  margin-bottom: 5px !important;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

  &:hover>button {
    color: white;
  }

  button,
  a {
    padding: 0.25rem !important;
    text-align: left;
    width: 100%;

    i {
      padding-left: 0.5rem;
      margin-right: 10px !important;
    }
  }
}

// ImageGallery styles
.image-gallery-slide.custom {
  >div {
    height: 80%;
  }

  &.image {
    height: 170px;
  }

  &.galleryMedia {
    height: 200px;

    .info {
      height: 20%;
      font-size: 80%;
    }

    video {
      height: 80%;
    }

    img {
      height: 100%;
    }
  }
}

.fullscreen .image-gallery-slide.custom {
  height: 90vh;

  >div {
    height: 100%;
  }

  &.galleryMedia {
    .info {
      height: 10%;
      font-size: 100%;
    }

    video {
      height: 90%;
    }
  }
}

.image-gallery-thumbnail.custom {
  background-color: #fff;
  height: 100px;

  >div {
    height: 100%;

    >img {
      height: 100%;
      object-fit: cover;
    }
  }
}