/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
$i: 0;

@while $i<=50 {
  .p {
    &-#{$i} {
      padding: #{$i}px;
    }

    &-t-#{$i} {
      padding-top: #{$i}px;
    }

    &-b-#{$i} {
      padding-bottom: #{$i}px;
    }

    &-l-#{$i} {
      padding-left: #{$i}px;
    }

    &-r-#{$i} {
      padding-right: #{$i}px;
    }
  }

  .m {
    &-#{$i} {
      margin: #{$i}px;
    }

    &-t-#{$i} {
      margin-top: #{$i}px;
    }

    &-b-#{$i} {
      margin-bottom: #{$i}px;
    }

    &-l-#{$i} {
      margin-left: #{$i}px;
    }

    &-r-#{$i} {
      margin-right: #{$i}px;
    }
  }

  $i: $i+5;
}

/*====== Padding , Margin css ends ======*/

/*====== text-color, background & border css starts ======*/
@each $value in $color-name {
  $i: index($color-name, $value);

  .bg-#{$value} {
    background-color: nth($color-color, $i), ;
  }

  .text-#{$value} {
    color: nth($color-color, $i);
  }

  .b-#{$value} {
    border: 1px solid nth($color-color, $i);
  }
}

/*====== text-color, background & border css ends ======*/
/*====== [ overrides bootstrap color ]text-color, background & border css starts ======*/
@each $value in $color-bt-name {
  $i: index($color-bt-name, $value);

  .bg-#{$value} {
    background-color: nth($color-bt-color, $i) !important;
  }

  .text-#{$value} {
    color: nth($color-bt-color, $i) !important;
  }

  .b-#{$value} {
    border: 1px solid nth($color-bt-color, $i) !important;
  }

  .text-h-#{$value}:hover {
    color: nth($color-bt-color, $i) !important;
  }
}

/*====== [ overrides bootstrap color ]text-color, background & border css ends ======*/
/*====== border color css starts ======*/
@each $value in $color-bt-name {
  $i: index($color-bt-name, $value);

  .b-#{$value} {
    border: 1px solid nth($color-bt-color, $i);
  }

  .border-bottom-#{$value} td {
    border-bottom: 1px solid nth($color-bt-color, $i);
  }

  .border-bottom-#{$value} th {
    border-bottom: 1px solid nth($color-bt-color, $i) !important;
  }
}

/*====== border color css ends ======*/

/*====== Card top border css starts ======*/
@each $value in $color-name {
  $i: index($color-name, $value);

  .card-border-#{$value} {
    border-top: 4px solid nth($color-color, $i);
  }
}

/*====== Card top border ends ======*/
/*====== Flex-size css starts ======*/
$i: 1;

@while $i<=5 {
  .flex-#{$i} {
    flex: #{$i};
  }

  $i: $i+1;
}

.flex-0-5 {
  flex: 1.5
}

.flex-1-5 {
  flex: 1.5
}

/*====== Font-size css ends ======*/
/*====== Font-size css starts ======*/
$i: 10;

@while $i<=80 {
  .f-#{$i} {
    font-size: #{$i}px;
  }

  $i: $i+2;
}

.f-08r {
  font-size: 0.8rem;
}

.f-1r {
  font-size: 1rem;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
$i: 100;

@while $i<=900 {
  .f-w-#{$i} {
    font-weight: #{$i};
  }

  $i: $i+100;
}

/*====== Font-weight css ends ======*/
/*====== text-color, background color css starts ======*/
@each $value in $social-name {
  $i: index($social-name, $value);

  .bg-#{$value} {
    background: nth($social-color, $i);
  }

  .text-#{$value} {
    color: nth($social-color, $i);
  }
}

/*====== text-color css ends ======*/
/*====== width, Height css starts ======*/
$i: 20;

@while $i<=150 {
  .wid-#{$i} {
    width: #{$i}px;
  }

  .hei-#{$i} {
    height: #{$i}px;
  }

  $i: $i+5;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

/*====== width, Height css ends ======*/
/*====== border-width css starts ======*/
$i: 1;

@while $i<=8 {
  .b-wid-#{$i} {
    border-width:#{$i}px;
  }

  $i: $i+1;
}

/*====== border-width css ends ======*/